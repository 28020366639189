import React from 'react';

function Simulador(props) {
    return (
        <div>
            <h1>Simulador</h1>
        </div>
    )
}
  
export default Simulador