import React from 'react';

function Acompanhamento(props) {
    return (
        <div>
            <h1>Acompanhamento</h1>
        </div>
    )
}
  
export default Acompanhamento