import React from 'react';

function Manutencao(props) {
    return (
        <div>
            <h1>Manutenção</h1>
        </div>
    )
}
  
export default Manutencao